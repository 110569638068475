<template>
  <div class="teacherText">
    <div class="video"  v-show="videoShow">
      <p class="returnbtn" @click="close"></p>
      <video ref="video" @ended="onendedVideo" style="width:100%;height:100%;object-fit: fill;border-radius:55px;" controlslist="nodownload" controls>
        <!-- <source src="https://wlkt-repo-test.oss-cn-shanghai.aliyuncs.com/video/1/1639722369546_sp.mp4" type="video/mp4"> -->
        <source src="" type="video/mp4">
        您的浏览器不支持Video标签。
      </video>
    </div>
    <main>
      <div class="swipe" v-if="isShow" v-show="allList.length">
        <div class="leftArrow">
          <img src="../../../assets/student/videoleft.png" @click="arrowClick('left')" />
        </div>
        <el-carousel @change="swipeChange" trigger="click" :autoplay="false" arrow="never" id="cardShow" ref="dropdownRef">
          <el-carousel-item v-for="item in allList" :key="item">
            <div class="swipeitem" v-for="(ele,index) in item" :key="index" @click="play(ele)">
              <div class="icon1 icon" v-show="ele.learnedTextVideo &&  ele.learnedTextVideo.xxzd == 1">
                <span>学</span>
                <span>习</span>
                <span>结</span>
                <span>束</span>
              </div>
              <div class="icon3 icon" v-show="ele.learnedTextVideo &&  ele.learnedTextVideo.xxzd == 0">
                <span>正</span>
                <span>在</span>
                <span>学</span>
                <span>习</span>
              </div>
              <div class="icon2 icon" v-show="ele.learnedTextVideo === null">
                <span>未</span>
                <span>开</span>
                <span>始</span>
                <span>学</span>
                <span>习</span>
              </div>
              <img :src="ele.ljdz + '?x-oss-process=video/snapshot,t_14000,f_jpg,w_800,h_600,m_fast'" alt="">
              <p>{{ele.zymc}}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="rightArrow">
          <img src="../../../assets/student/videoright.png" @click="arrowClick('right')" />
        </div>
        <div class="tigger">
          <li @click="tiggerclick(index)" v-for="(item,index) in allList" :class="tiggerIndex == index ? 'liActive' : ''" :key="index"></li>
        </div>
      </div>
      <div v-show="!allList.length" class="zwnr">
        暂无内容
      </div>
      <p class="returnbtn" @click="upperLever"></p>
    </main>
  </div>
  <Header :header_data="header_data"/>
</template>
<script>
import Header from "../components/zjfheader.vue";
import { getTextVideo, saveTextVideo } from '@/api/student'
import { nextTick, onMounted, reactive, ref, toRefs } from 'vue-demi'
import { getCurrentInstance, onBeforeUnmount } from 'vue'
import { ElMessage } from 'element-plus';
import {useStore} from "vuex";
export default {
  components: {
    Header
  },
  setup() {
    const store = useStore();
    const { appContext } = getCurrentInstance();
    // vue 实例
    const that = appContext.config.globalProperties;
    const dropdownRef = ref(null)
    const video = ref(null)
    onMounted(() => {
      // data.jxInfo = JSON.parse(sessionStorage.getItem('jxInfo'))
      data.textInfo = JSON.parse(sessionStorage.getItem('textInfo'))
      console.log(data.textInfo,'1111111111')
      data.header_data = [
        { title: "图谱英语 — ", path: "newEnglish" },
        { title: that.$route.query.zmc + " — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
        { title: that.$route.query.dymc + " — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
        { title: data.textInfo.kwmc, path: `teacherText?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` }
      ]
      getTextVideoFun()
    })
    // 拉取列表
    function getTextVideoFun() {
      getTextVideo({
        bbid: data.textInfo.bbid,
        // bjid: data.jxInfo.bjid,
        // jxid: data.jxInfo.jxid,
        xsid: ~~that.$store.state.userInfo.yhid,
        id: data.textInfo.id,
        jid: data.textInfo.jid,
        kmid: data.textInfo.kmid,
        njid: data.textInfo.njid,
        zid: data.textInfo.zid,
        zsdid: data.textInfo.zsdid
      }).then(res => {
        if (res.success) {
          data.isShow = false
          let arr = []
          res.result.forEach((ele, index) => {
            if (!arr[parseInt(index / 8)]) {
              arr[parseInt(index / 8)] = []
            }
            arr[parseInt(index / 8)].push(ele)
          })
          data.allList = arr
          console.log(arr,'232234')
          nextTick(() => {
            data.isShow = true
          })
        } else {
          ElMessage({
            type: 'warning',
            message: res.resultDesc
          })
        }
      })
    }
    const data = reactive({
      allList: [],
      isShow: true,
      // jxInfo: {},
      pages: {currentPage:1},
      tiggerIndex: 0,
      textInfo: {}, // 保存课文信息
      videoShow: false,
      currentObj: {},
      getVideoLength: false,
      header_data: [],
      uid: null
    })
    // 轮播change
    function swipeChange(val) {
      data.tiggerIndex = val
    }
    // 自定义轮播图切换
    const arrowClick = (val) => {
      if (val === "right") {
        if (data.pages.currentPage >= data.pages.total / 10) {
          data.pages.currentPage = 1;
        } else {
          ++data.pages.currentPage;
        }
        dropdownRef.value.next();
      } else {
        if (data.pages.currentPage <= 1) {
          data.pages.currentPage = Math.ceil(data.pages.total / 10);
        } else {
          --data.pages.currentPage;
        }
        dropdownRef.value.prev();
      }
    };
    // 自定义轮播指示器
    const tiggerclick = (val) => {
    }
    // 关闭视频
    const upperLever = () => {
      that.$router.replace({ name: 'teacherText', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid }})
    }
    // 关闭视频
    const close = () => {
      let h = Math.floor(video.value.currentTime / 3600)
      if ((h + '').length == 1) {
        h = '0' + h
      }
      let m = Math.floor(video.value.currentTime / 60)
      if ((m + '').length == 1) {
        m = '0' + m
      }
      let s = parseInt(video.value.currentTime) % 60
      if ((s + '').length == 1) {
        s = '0' + s
      }
      let ss = ((video.value.currentTime + '').substring((video.value.currentTime + '').indexOf('.') + 1)).substring(0,3)
      let timer = h + ':' + m + ':' + s + ':' + ss
      saveTextVideo({
        cjr: ~~that.$store.state.userInfo.yhid,
        xgr: ~~that.$store.state.userInfo.yhid,
        kwid: data.textInfo.id,
        spjd: timer,
        xsid: ~~that.$store.state.userInfo.yhid,
        xsmc: store.state.userInfo.xm,
        xxzd: 0,
        zybh: data.currentObj.spzybh,
        xgrq: new Date(),
        id: data.uid
      }).then(res => {
        if (res.success) {
          getTextVideoFun()
          video.value.pause()
        } else {
          ElMessage({
            type: 'warning',
            message: res.resultDesc
          })
        }
      })
      data.videoShow = false
    }
    // 播放视频
    const play = (val) => {
      console.log(val,4444)
      // if (val.learnedTextVideo) {
      //   data.uid = val.learnedTextVideo.id
      // }
      data.currentObj = val
      video.value.src = val.ljdz
      // let timer = val.learnedTextVideo && (val.learnedTextVideo.spjd.substring(0,2) - 0)*3600 + (val.learnedTextVideo.spjd.substring(3,5) - 0)*60 + (val.learnedTextVideo.spjd.substring(6,8) - 0) + '.' + val.learnedTextVideo.spjd.substring(9) - 0
      // video.value.currentTime = timer
      setTimeout(() => {
        // if (isNaN(video.value.duration)) {
        //   data.getVideoLength = false
        // } else {
        //   data.getVideoLength = true
        // }
        // if (video.value.duration == timer) {
        //   video.value.currentTime = 0
        // }
        nextTick(() => {
          if (!isNaN(video.value.duration)) {
            data.videoShow = true
            video.value.play()
          }
        })
      }, 100);
    }
    // 视频播放完成
    const onendedVideo = () => {
      let h = Math.floor(video.value.currentTime / 3600)
      if ((h + '').length == 1) {
        h = '0' + h
      }
      let m = Math.floor(video.value.currentTime / 60)
      if ((m + '').length == 1) {
        m = '0' + m
      }
      let s = parseInt(video.value.currentTime) % 60
      if ((s + '').length == 1) {
        s = '0' + s
      }
      let ss = ((video.value.currentTime + '').substring((video.value.currentTime + '').indexOf('.') + 1)).substring(0,3)
      let timer = h + ':' + m + ':' + s + ':' + ss
      saveTextVideo({
        // bjid: data.jxInfo.bjid,
        // bjmc: data.jxInfo.bjmc,
        cjr: ~~that.$store.state.userInfo.yhid,
        xgr: ~~that.$store.state.userInfo.yhid,
        // jxid: data.jxInfo.jxid,
        // jxmc: data.jxInfo.jxmc,
        kwid: data.textInfo.id,
        spjd: timer,
        xsid: ~~that.$store.state.userInfo.yhid,
        xsmc: store.state.userInfo.xm ,
        xxzd: 1,
        zybh: data.currentObj.spzybh,
        xgrq: new Date(),
        id: data.uid
      }).then(res => {
        if (res.success) {
          ElMessage({
            type: 'success',
            message: '已经看完一个视频了哦'
          })
          getTextVideoFun()
          video.value.pause()
        } else {
          ElMessage({
            type: 'warning',
            message: res.resultDesc
          })
        }
      })
      data.videoShow = false
    }
    // 离开页面
    onBeforeUnmount(() => {
      console.log(1234567)
      video.value.pause()
    })
    return {
      ...toRefs(data),
      swipeChange,
      arrowClick,
      dropdownRef,
      video,
      tiggerclick,
      upperLever,
      close,
      play,
      onendedVideo
    }
  },
}
</script>
<style lang="scss" scoped>
.video {
  width: 92.6vw;
  height: 40.5vw;
  position: fixed;
  top: 55.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.zwnr {
  font-size: 30px;
  color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.close {
  width: 3.645833vw;
  height: 3.645833vw;
  background: url("../../../assets/student/no.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  right: 1vw;
  top: 1vw;
  z-index: 2;
  cursor: pointer;
}
.returnbtn {
  width: 3.645833vw;
  height: 3.645833vw;
  background: url("../../../assets/student/wordreturn.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  left: 2vw;
  bottom: 45%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}
.teacherText {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
  main {
    width: 94.166666666vw;
    height: 42vw;
    background: url("../../../assets/student/wordbanner2.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    .swipe {
      margin: auto;
      position: absolute;
      top: 3vw;
      left: 50%;
      bottom: 5vw;
      transform: translateX(-50%);
      box-sizing: border-box;
      width: calc(100% - 18vw);
      .rightArrow {
        position: absolute;
        right: -5vw;
        top: 50%;
        transform: translateY(-50%);
        width: 3.64583vw;
        height: 3.64583vw;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .leftArrow {
        position: absolute;
        left: -5vw;
        top: 50%;
        transform: translateY(-50%);
        width: 3.64583vw;
        height: 3.64583vw;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .tigger {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -8%;
        li {
          float: left;
          margin-right: 13px;
          padding: 4px 10px;
          background: #B6E0FD;
          border-radius: 4px;
          cursor: pointer;
        }
        .liActive {
          background: #2CA2F5;
        }
      }
    }
    .swipeitem {
      float: left;
      margin-bottom: 1.2vw;
      margin-right: 1.5625vw;
      width: 24.212157vw;
      height: 16.12916vw;
      background: #2CA2F5;
      border-radius: 20px;
      position: relative;
      cursor: pointer;
      img {
        display: block;
        width: 99%;
        height: 83.6%;
        border-radius: 20px;
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
      }
      p {
        font-size: 1.26vw;
        color: #fff;
        text-align: center;
        font-weight: 600;
        line-height: 2.2vw;
      }
      .icon {
        display: flex;
        flex-direction: row;
        flex-direction: column;
        /* align-items: center; */
        /* text-align: center; */
        padding-left: .9vw;
        box-sizing: border-box;
        z-index: 2;
      }
      .icon1 {
        width: 3.59375vw;
        height: 7.083333333333333vw;
        background: url('../../../assets/student/verticaljieshu.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -.5vw;
        left: -.5vw;
        font-size: 1vw;
        color: #fff;
      }
      .icon2 {
        width: 3.59375vw;
        height: 7.083333333333333vw;
        background: url('../../../assets/student/verticalwks.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -.5vw;
        left: -.5vw;
        font-size: 1vw;
        color: #fff;
      }
      .icon3 {
        width: 3.59375vw;
        height: 7.083333333333333vw;
        background: url('../../../assets/student/verticalzhengz.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -.5vw;
        left: -.5vw;
        font-size: 1vw;
        color: #fff;
      }
    }
    .swipeitem:nth-child(3) {
      margin-right: 0;
    }
    .swipeitem:nth-child(6) {
      margin-right: 0;
    }
  }
  .returnbtn {
    width: 3.645833vw;
    height: 3.645833vw;
    background: url("../../../assets/student/wordreturn.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    left: 2vw;
    bottom: 2vw;
    z-index: 2;
    cursor: pointer;
  }
  /deep/.el-carousel--horizontal {
    height: 100%;
    width: 100%;
  }
  /deep/.el-carousel__container {
    width: 100%;
    height: 100%;
  }
  /deep/.el-carousel__item.is-animating {
    width: 100%;
    height: 100%;
    padding-top: 0.46875vw;
    padding-left: 0.36458333333333334vw;
  }
  /deep/.el-carousel__indicators--horizontal {
    display: none;
  }
}
</style>